<template>
	<div
		class="authPage"
		:class="{
			'pb-104': helpers.layout.isMobile(),
			'height-minus-top-bar': !helpers.layout.isMobile(),
		}"
	>
		<div
			class="alert alert-danger text-white d-flex align-items-center"
			role="alert"
			aria-live="assertive"
			aria-atomic="true"
		>
			<span class="material-icons-outlined me-1" aria-hidden="true">
				error_outline
			</span>
			<template v-if="route.query.message">{{ route.query.message }}</template>
			<template v-else>403</template>
		</div>
	</div>
</template>

<script>
import { inject } from "vue";
import { useRoute } from "vue-router";
export default {
	setup(props, context) {
		const _ = inject("_");
		const route = useRoute();
		const helpers = inject("helpers");
		console.log(route);
		return {
			route,
			helpers,
		};
	},
};
</script>

<style></style>
